<template>
  <div class="exam-root-2">
    <div class="exam-top">
      <img
        src="../../assets/arrow_circle_back.png"
        style="cursor: pointer; width: 36px; height: auto"
        @click="goBack"
      />
      <img
        src="../../assets/loginLogo.png"
        style="height: 30px; margin-left: 30px"
      />

      <div class="session-title13" @click="exportAward" >
        下载成绩单
      </div>

    </div>

    
    <div class="exam-content">
      <div class="transcriptContainer" style="margin-bottom: 10px">
        <!-- 背景图 -->
        <img src="../../assets/transcript_felc_new.jpg" class="model_p" />

        <div class="yearClass">2 0 2 3 - 2 0 2 4</div>
        <!-- 姓名 -->
        <div class="name">
          {{
            item.student_info.student_lastName +
            item.student_info.student_givenName +
            " " +
            upfirstName(item.student_info.student_givenName_pinyin) +
            " " +
            upfirstName(item.student_info.student_lastName_pinyin) 
            
            
          }}
        </div>
        <!-- 信息 -->
        <div class="info">
          <div>
            <span class="tag">邮箱: </span>
            <span class="content">{{ item.student_info.student_email }}</span> |
            <span class="tag">性别: </span>
            <span class="content">{{
              item.student_info.student_gender == "男" ? "男" : "女"
            }}</span>
            <!-- |
            <span class="tag">预计毕业年份: </span>
            <span class="content">{{
              item.student_info.student_graduation
            }}</span> -->
          </div>
          <div style="margin-top: 6px">
            <span class="tag">学校: </span>
            <span class="content">{{ item.account_info.account_name }}</span>
          </div>
        </div>
        <!-- 团队成绩 -->
        <div v-if="haveTeam == true" class="scoreTitle1">团队项目成绩 </div>

        <div class="score">
          <div v-for="(score , index) in item.team_score_info" :key="score._id.$id" class="teamItem">
            <div v-if="score.score_type == 'team'" style="">
              <div class="title">
                {{ score.application_event }}
              </div>
              <div style="margin-top: 5px;font-size: 12px;">
                分数 : <span style="margin-left: 5px;">{{ score.total_score }}</span
                >/{{ score.full_score }}<br>
                <!-- <span style="margin-top : 15px"></span>Percentile: Top<span>{{ score.percentile }}</span> -->
              </div>

              <!-- <div style="margin-top: 5px">
                # of Competitors: {{ score.competitor_count }}
              </div> -->
            </div>
          </div>
        </div>
        <!-- 个人成绩 -->
        <div class="scoreTitle2">个人项目成绩 </div>

        <div class="score1">

          <div v-for="(score , index) in item.person_score_info" :key="score._id.$id" class="oneClass">
            <div
              v-if="score.score_type == 'personal'"
              style="margin-bottom: 10px"
            >
              <div class="title">
                {{ score.application_event }}
              </div>
              <div style="margin-top: 5px;font-size: 12px;">
                分数 :  <span style="margin-left: 5px;">{{ score.total_score }}</span
                >/{{ score.full_score }}
                <!-- <span style="margin-left: 10px"></span>百分位:前
                <span>{{ score.percentile }}</span> -->
              </div>
              <!-- <div style="margin-top: 5px">
                # of Competitors：{{ score.competitor_count }}
              </div> -->
            </div>
          </div>
        </div>
        <div class="time1">{{'2024年8月9日'}}</div>

      </div>
    </div>
  </div>
</template>

<script>
import { findMyTranscript ,checkTranscriptOpen} from "../../api/index";
import { getUserId, getProjectCode } from "../../utils/store";
import "../../assets/common/font.css";

export default {
  data() {
    return {
      item: {},
      haveTeam:false
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      findMyTranscript(getUserId(), getProjectCode()).then((res) => {
        loading.close();
        this.item = res.data.data;

        this.item.person_score_info = []
        this.item.team_score_info = []

        for (var i = 0; i < this.item.score_info.length; i++) {

          var info = this.item.score_info[i]

          console.log(1111,info.score_type)

          if (info.score_type == 'team'){
            this.haveTeam = true;
            this.item.team_score_info.push(info)
          }else{
            this.item.person_score_info.push(info)
          }

        }

      });
    },
    handleEventName(event) {
      if (event == undefined) {
        return "";
      }
      let event_array = event.split(" ");
      console.log(111, event, event_array);
      return event_array[0];
    },

    goBack() {
      this.$router.go(-1);
    },
    upfirstName(name) {
      let transferName = name.toLowerCase();
      return transferName.charAt(0).toUpperCase() + transferName.slice(1);
    },
    rank(number) {
      if (number === 1) {
        return "st";
      } else if (number === 2) {
        return "nd";
      } else if (number === 3) {
        return "rd";
      } else {
        return "th";
      }
    },
    printPersonal() {
      let url = this.$router.resolve({
        path: "/blank",
      });
      let newWindow = window.open(url.href, "_blank"); //打印窗口要换成页面的url
      let obj = document.getElementById("cerP");

      let docStr = obj.innerHTML;

      newWindow.document.write(docStr);

      newWindow.document.close();

      newWindow.print();

      newWindow.close();
    },

    exportAward() {
      checkTranscriptOpen(getProjectCode()).then((res) => {
        if (res.data) {
          this.$router.push({
            path: "/awardExportTran",
          });
        } else {
          this.$message.error("电子成绩单还未公布，请稍后再来查看");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "aleo-bold";
  src: url("../../assets/fonts/aleo-bold.ttf");
}
@font-face {
  font-family: "Aleo-Bold-2";
  src: url("../../assets/fonts/Aleo-Bold-2.otf");
}
@font-face {
  font-family: "Aleo-Italic-4";
  src: url("../../assets/fonts/Aleo-Italic-4.otf");
}
@font-face {
  font-family: "Aleo-Light-5";
  src: url("../../assets/fonts/Aleo-Light-5.otf");
}
@font-face {
  font-family: "Aleo-Regular-7";
  src: url("../../assets/fonts/Aleo-Regular-7.otf");
}
.el-dialog {
  width: 320mm;
}
.exam-content {
  width: 100%;
  flex: 1;
  background-image: url("../../assets/exam-content-bg.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  padding: 30px 9vw;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.awardTitle {
  font-weight: bold;
  text-align: center;
  color: #0f429e;
  font-size: 60px;
  margin-top: 30px;
  font-size: 12px;
  font-family: Montserrat-SemiBold;
}
.awardTitle1 {
  font-weight: bold;
  text-align: left;
  color: #0036a0;
  font-size: 30px;
  margin-top: 60px;
  margin-left: 80px;
  font-family: Montserrat-SemiBold;
}
.awardLine {
  background-color: #00359f;
  margin-left: 80px;
  margin-right: 80px;
  height: 1px;
  margin-top: 10px;
  font-family: Montserrat-SemiBold;
}
.scoreLab1 {
  margin-top: 60px;
  margin-right: 10px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: right;
  font-size: 30px;
  color: #fd5900;
  float: right;
  font-family: Montserrat-SemiBold;
}

.scoreLab2 {
  margin-top: 65px;
  margin-right: 80px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: right;
  color: #0f429e;
  font-size: 25px;
  float: right;
  font-family: Montserrat-SemiBold;
}
.awardSubTitle {
  text-align: left;
  color: #2d50b0;
  font-size: 15px;
  margin-top: 20px;
  margin-left: 80px;
  font-family: Montserrat-SemiBold;
}
.returnStyle {
  position: absolute;
  // display: flex;
  // margin-bottom: 10px;
  // align-items: center;
  bottom: 40px;
  margin-left: 45%;
  font-weight: 600;
  width: 140px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  // align-content: center;
  // align-items: center;
  border-radius: 15px;
  background-color: #2d50b0;
  color: white;
  font-family: Montserrat-SemiBold;
}
.box {
  display: flex;
  justify-content: left;
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 20px;
  // height: 250px;
  // background-color: #FD5900;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: left;
  align-content: left;
  text-align: left;
  float: left;
}
.child {
  // width: 50px;
  // background-color: aqua;
  height: 30px;
  padding-left: 10px;
}
.infoStyle {
  display: flex;
  border: 1px solid #2d50b0;
  border-radius: 16px;
  color: #2d50b0;
  margin-left: 20px;
  margin-top: -25px;
  font-family: Montserrat-SemiBold;
}
.infoImg {
  width: 14px;
  height: 14px;
}
.exam-top {
  height: 60px;
  background: #00348b;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 9vw;
}

.exam-root-2 {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.session-title13 {
    // float: left;
    font-weight: 600;
    color: #2d50b0;
    font-size: 18px;
    align-content: center;
    text-align: center;
    width: 130px;
    margin-left: 10px;
    height: 38px;
    border-radius: 4px;
    background-color: white;
    // background-color: rebeccapurple;
    line-height: 38px;
    // font-family: Montserrat-SemiBold;
    border: 1px solid #00348b;
    cursor: pointer;
  }

.transcriptContainer {
  position: relative;
  margin-bottom: -4px;
  .model_p {
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name {
    position: absolute;
    top: 356px;
    left: 85px;
    font-size: 25px;
    font-weight: 500;
  }
  .info {
    position: absolute;
    top: 404px;
    left: 85px;
    font-size: 15px;
    .tag {
      font-family: "Aleo-Bold-2";
    }
    .content {
      font-family: "Aleo-Regular-7";
    }
  }
  .score {
    position: absolute;
    top: 820px;
    width: 620px;
    // display: flex;
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    left: 85px;
    font-size: 15px;
    font-family: "Aleo-Regular-7";
    span {
      font-family: "Aleo-Bold-2";
      font-size: 17px;
    }
    .title {
      font-family: "aleo-bold";
    }
    .teamItem{
      width: 280px;
      // background-color: yellow;
      margin-right: 20px;
    }
    
  }
  .scoreTitle2{
      // font-size: 20px;
      // margin-bottom: 10px;
      // font-weight: 500;
      // color: #0f429e;
      position: absolute;
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: 550;
      color: #0f429e;
      top: 470px;
      width: 220px;
      left: 85px;
    }
  .scoreTitle1{
    position: absolute;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 550;
    color: #0f429e;
    top: 780px;
    width: 220px;
    left: 85px;

  }
  .score1 {
    position: absolute;
    top: 500px;
    width: 620px;
    // background-color: rebeccapurple;
    left: 85px;
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    font-size: 15px;
    font-family: "Aleo-Regular-7";
    span {
      font-family: "Aleo-Bold-2";
      font-size: 17px;
    }
    .title {
      font-family: "aleo-bold";
    }
    .scoreTitle{
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: 500;
      color: #0f429e;
    }

    .oneClass{
      margin-top: 15px;
      width: 280px;
      // background-color: yellow;
      margin-right: 20px;
    }  
    

  }
  .yearClass{
    position:absolute;
    font-size: 20px;
    color: white;
    left: 135px;
    top: 237px;
    // background-color: rebeccapurple;
  }
  
  .time1{
        position: absolute;
        top: 950px;
        width:320px;
        left:85px;
        font-size: 18px;
        font-weight: 600;
        color: #2A358A;
        // font-family: 'Aleo-Regular-7';
        // span{
        //     font-family: 'Aleo-Bold-2';
        //     font-size: 14px;
        // }
        // .title{
        //     font-family: 'aleo-bold';
        // }
    }
}
</style>
